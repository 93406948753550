import { BaseRestDataProvider } from './BaseRestDataProvider'
import axios, { AxiosError, AxiosResponse } from 'axios'
import {
	getAccessToken,
	getRefreshToken,
	removeAccessToken,
	removeRefreshToken,
	setAccessToken,
	setRefreshToken,
} from '@/common/token'
import { LastUrlService } from '@utils'
import { ssoPath } from '@/api/path/ssoPath'

const updateRefreshToken = (refresh) => {
	const refreshAxios = axios.create({
		baseURL: `${process.env.REACT_APP_SSO_HOST}/`,
	})
	return refreshAxios.post(ssoPath.refresh, { refresh })
}

axios.interceptors.request.use(
	(config) => {
		const token = getAccessToken()
		if (token) config.headers['Authorization'] = `Bearer ${token}`
		return config
	},
	(error) => {
		Promise.reject(error).catch((res: { status: string }) => {
			throw res?.status
		})
	},
)

let debounce

axios.interceptors.response.use(
	(response) => {
		return response
	},
	async (error: AxiosError<never>) => {
		const errorData = error.response
		const originalConfig = error.config
		const currentAccess = getAccessToken()
		const currentRefresh = getRefreshToken()
		if (errorData.status === 401) {
			if (currentAccess && currentRefresh) {
				try {
					const newTokens: any = await updateRefreshToken(currentRefresh)
					setAccessToken(newTokens.data.access)
					if (newTokens.data.refresh) {
						setRefreshToken(newTokens.data.refresh)
					}
					return axios(originalConfig)
				} catch (e) {
					removeAccessToken()
					removeRefreshToken()
					LastUrlService.save(window.location.href)
					window.location.href = window.location.origin
					return
				}
			}
		}
		return Promise.reject(error).catch((error: AxiosError) => {
			throw error
		})
	},
)

export class AuthDataProvider extends BaseRestDataProvider {
	login = (username: string, password: string) => {
		return axios
			.post(`${this.host}/${ssoPath.token}`, {
				username,
				password,
			})
			.then((res: AxiosResponse<{ access: string; refresh: string; links: Array<string> }>) => {
				return res?.data
			})
	}
}
