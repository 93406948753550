import React, { useEffect, useState } from 'react'
import '@frontend-modules/error-handler/assets/styles/error-handler-styles.scss'

import { AccessHelper } from '@frontend-modules/access-controller'
import { loadConstants } from '@/store/actions/constants'
import { loadMeta } from '@store/actions/meta'
import { loadProfile } from '@/store/actions/profile'

import { useDispatch, useSelector } from 'react-redux'
import { State } from '@/store/store'

import Routes from '@/src-new/Routes'

export const App: React.FC = () => {
	const dispatch = useDispatch()
	const { entities } = useSelector((state: State) => state.meta)
	const [entitiesState, setEntities] = useState(entities)

	useEffect(() => {
		dispatch(loadProfile({}))
		dispatch(loadConstants())
		dispatch(loadMeta())
	}, [dispatch])

	/**
	 * @description - установка данных для access permission проверок
	 */
	useEffect(() => {
		AccessHelper.setData(entities)
		setEntities(entities)
	}, [entities])

	return <Routes />
}
