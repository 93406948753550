import { BaseRestDataProvider } from './BaseRestDataProvider'
import axios from 'axios'
import { LoadDocumentsResponse, LoadGeneratedDocumentsPayload } from '../store/types/document'
import { getFilterParams } from '../utils/filters'

export class DocumentsDataProvider extends BaseRestDataProvider {
	uploadDocuments = async (
		student_id: number,
		documents: any,
		user_id: number,
		uploaded_by: string,
		upload_doc_type: number,
	) => {
		return axios.all(
			documents.map(async (document: any) => {
				const file = new FormData()
				file.append('file', document, document.name)
				if (student_id) {
					file.append('student', String(student_id))
				}
				// file.append("state", "aproved");
				file.append('file_name', document.name)
				file.append('user', String(user_id))
				file.append('uploaded_by', uploaded_by)
				file.append('upload_doc_type', String(upload_doc_type))

				return axios.post(`${this.host}/api/upload/`, file).then((res) => res.data)
			}),
		)
	}

	loadDocuments = async (search: Record<string, string | number>): Promise<LoadDocumentsResponse> => {
		let searchStr = ''
		Object.keys(search).forEach((key) => {
			if (search[key]) {
				searchStr += `${key}=${search[key]}&`
			}
		})

		return await axios
			.get<LoadDocumentsResponse>(`${this.host}/api/upload/?${searchStr}`)
			.then((response) => response.data)
			.catch(() => ({
				count: 0,
				next: null,
				previous: null,
				results: [],
			}))
	}

	approveDocument = async (document_id: string) =>
		axios.put(`${this.host}/api/upload/${document_id}/approve/`).then((res) => res.data)

	loadGeneratedDocuments = async (payload: LoadGeneratedDocumentsPayload) => {
		const searchParam = getFilterParams(payload.filter)
		return axios
			.get(`${this.host}/api/report/generated_documents/?page=${payload.page}${searchParam}`, {
				withCredentials: true,
			})
			.then((res) => res.data)
	}

	deleteDocument = (id: number) => {
		return axios.delete(`${this.host}/api/files/${id}/`)
	}
}
