import React, { FC, useState } from 'react'
import {
	AppButton,
	AppCard,
	AppFileBaseUpload,
	AppPopover,
	AppSkeleton,
	AppSpecialButton,
	AppText,
} from '@frontend-modules/ui-kit'
import './DocumentList.scss'
import { IDocumentListProps } from './DocumentList.types'
import { isEmptyList } from '@frontend-modules/frontend-utils'
import { Empty } from 'antd'
import { PaymentsService } from '@services'
import axios from 'axios'

const payments = new PaymentsService()

const DocumentList: FC<IDocumentListProps> = (props) => {
	const { type, data, isLoading, isLoadingMore, onLoad } = props

	const { results, pagination } = data || {}

	const isEmpty = isEmptyList(results)
	const [isUploading, setUploading] = useState(false)

	const uploadFile = async (params) => {
		setUploading(true)
		const req = type === 'pays' ? payments.uploadRegistry : payments.uploadPOFile
		const res = await req(params)
		setUploading(false)
		return res
	}

	const onScroll = (event) => {
		const { target } = event
		if (Math.round(target.scrollTop) + target.offsetHeight >= target.scrollHeight) {
			if (pagination) {
				if (pagination?.currentPage < pagination?.maxPage && pagination?.maxPage > 1) {
					if (!isLoadingMore) {
						onLoad?.({ limit: pagination.limit, page: pagination?.currentPage + 1, isLoadingMore: true })
					}
				}
			}
		}
	}

	const onDownload = async (item) => {
		const text = await axios.get(item.fileUrl, { responseType: 'blob' })
		const reader = new FileReader()
		reader.readAsText(text.data, 'windows-1251')
		reader.onload = function (event) {
			const result = event.target.result
			if (typeof result === 'string' && (result?.includes('?') || result?.includes('°'))) {
				const reader2 = new FileReader()
				reader2.readAsText(text.data, 'CP-1251')
				reader2.onload = function (event2) {
					const correctResult = event2.target.result
					downloadText(correctResult, item)
				}
			} else {
				downloadText(result, item)
			}
		}

		function downloadText(textContent, item) {
			const blob = new Blob([textContent], {
				type: 'text/plain;charset=windows-1251',
			})
			const url = window.URL.createObjectURL(blob)
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', `${item.title}.${item.fileExt}`)
			document.body.appendChild(link)
			link.click()
			link.remove()
		}
	}

	return (
		<AppCard
			className={'document-list'}
			title={type === 'pays' ? 'Реестры платежей' : 'Банковские выписки'}
			extra={
				<AppFileBaseUpload
					format={[
						'.csv',
						'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						'application/vnd.ms-excel',
						'text/plain',
					]}
					onUploaded={() => {
						onLoad?.({ limit: pagination.limit, page: 1, isLoadingMore: false })
					}}
					accessKey={
						type === 'pays' ? 'payments.registries.actions.upload' : 'payment_orders.POFiles.actions.upload'
					}
					uploadFileApiRequest={uploadFile}
					onChange={undefined}
				>
					<AppButton isLoading={isUploading} type={'medium-outline'} label={'Загрузить'} />
				</AppFileBaseUpload>
			}
		>
			<div className={'document-list-content'} onScroll={onScroll}>
				{isLoading && (
					<div className={'document-list-content-skeleton'}>
						<AppSkeleton.Button style={{ height: 41, width: '100%' }} active />
						<AppSkeleton.Button style={{ height: 41, width: '100%' }} active />
						<AppSkeleton.Button style={{ height: 41, width: '100%' }} active />
					</div>
				)}
				{isEmpty && !isLoading && <Empty className={'empty'} description={'Документы не найдены'} />}
				{!isEmpty &&
					!isLoading &&
					results?.map((item, index) => (
						<AppCard key={index} className={`document-list-content-item ${item.status}`}>
							<div className={'document-list-content-item-content'}>
								<AppText text={item?.title} />
								<AppPopover
									trigger={'click'}
									overlayClassName={'document-list-popup-content'}
									content={
										<>
											{item.status === 'recognition' && (
												<AppText text={'Идет загрузка файла...'} />
											)}
											{(item.status === 'success' || item.status === 'not_success') && (
												<>
													<AppText text={'Загружено:'} />
													<AppText text={`Входящие ПП: <b>${item.incomingCount}</b>`} />
													<AppText
														text={`Новые (не дублируются): <b>${item.newIncomingCount}</b>`}
													/>
													{type !== 'pays' && (
														<AppText text={`Исходящие ПП: <b>${item.outgoingCount}</b>`} />
													)}
													{type !== 'pays' && (
														<AppText
															text={`Новые (не дублируются): <b>${item.newOutgoingCount}</b>`}
														/>
													)}
													{!!item.uploadErrors && (
														<>
															<hr />
															<AppText
																text={`Не загружено: <b>${item.uploadErrors}</b>`}
															/>
															<AppText
																text={`Причина: <b>${item.errorsDetail.map(
																	(err) => `\n${err}`,
																)}`}
															/>
														</>
													)}
												</>
											)}
										</>
									}
								>
									<AppButton type={'small-transparent'} label={'Подробнее'} />
								</AppPopover>
								<AppSpecialButton
									type={'bordered'}
									label={''}
									icon={'download-m'}
									accessKey={
										type === 'pays'
											? 'payments.registries.actions.download'
											: 'payment_orders.POFiles.actions.download'
									}
									onClick={() => onDownload(item)}
								/>
							</div>
						</AppCard>
					))}
				{isLoadingMore && <AppText className={'document-list-content-loading-more'} text={'Загрузка...'} />}
			</div>
		</AppCard>
	)
}

export default DocumentList
