import { BaseRestDataProvider } from './BaseRestDataProvider'
import axios from 'axios'

export class MessageDataProvider extends BaseRestDataProvider {
	sendMessage = (payload: any) => {
		return axios.post(`${this.host}/api/send/message/`, payload, {
			withCredentials: true,
			xsrfCookieName: 'csrftoken',
			xsrfHeaderName: 'X-CSRFToken'
		}).then(res => res.data)
	}
}
