import { BaseRestDataProvider } from './BaseRestDataProvider'
import axios from 'axios'

export class ChatDataProvider extends BaseRestDataProvider {
	sendMessage = (payload: any) => {
		return axios.post(`${this.host}/api/processes/send/message/${payload.student}/`, { text: payload.message }).then(res => res.data)
	}

	loadChat = (student_id: any) => {
		return axios.get(`${this.host}/api/processes/messages/${student_id}/`).then(res => res.data)
	}

	loadUnreadMessages = () => {
		return axios.get(`${this.host}/api/processes/chats/`).then(res => res.data) 
	}
}
