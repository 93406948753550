import { combineEpics, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { ActionType, getType } from 'typesafe-actions'
import { FuncEpic } from '@/common/types'
import { showAlert } from '@/store/actions/alert'
import { login, onLoginError, onLoginSuccess } from '../actions/login'
import { setAccessToken, setRefreshToken, removeRefreshToken, removeAccessToken } from '@/common/token'
import { LastUrlService } from '@utils'

const loginEpic: FuncEpic = (action$: any, store$, deps) => {
	return action$.pipe(
		ofType(getType(login)),
		switchMap(({ payload: { username, password } }: ActionType<typeof login>) => {
			return from(deps.authDataProvider.login(username, password)).pipe(
				switchMap((data) => {
					const isAvailable = data.links.some((item) => item.includes('erp'))

					if (data.access && isAvailable) {
						setAccessToken(data.access)
						setRefreshToken(data.refresh)
						const needOpenUrl = LastUrlService.get()
						window.location.href = needOpenUrl.length ? needOpenUrl : '/'
					} else {
						const url = data?.links?.[0]
						if (url) {
							removeAccessToken()
							removeRefreshToken()
							window.location.href = url
						}
					}
					return of(onLoginSuccess(data))
				}),
				catchError((err) => {
					return of(
						onLoginError(err),
						showAlert({ title: 'Ошибка!', text: 'Неверный логин или пароль', type: 'error' }),
					)
				}),
			)
		}),
	)
}

export const loginEpics = combineEpics(loginEpic)
