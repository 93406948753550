import { Middleware, Dispatch } from 'redux'
import { State, Action } from '../store'
import { getAccessToken, removeAccessToken, removeRefreshToken } from '@/common/token'
import jwt from 'jsonwebtoken'
import moment from 'moment'
import { getType } from 'typesafe-actions'
import { login, onLoginError, onLoginSuccess } from '@store/actions/login'
import { LastUrlService } from '@utils'
import { on403Logout } from '@/common/logout'

export const authMiddleware: Middleware<State, State, Dispatch<Action>> =
	(): ((next: Dispatch<Action>) => (action: Action) => Action) =>
	(next: Dispatch<Action>): ((action: Action) => Action) =>
	(action: Action): Action => {
		const { type, payload }: any = action
		if (
			window.location.pathname !== '/login' &&
			![getType(login), getType(onLoginSuccess), getType(onLoginError)].includes(type)
		) {
			LastUrlService.save(window.location.href)
			const token = getAccessToken()
			const decoded: any = jwt.decode(token)
			if (!token) {
				removeAccessToken()
				removeRefreshToken()
				window.location.href = '/login'
			}

			if (moment().unix() > decoded) {
				removeAccessToken()
				removeRefreshToken()
				window.location.href = '/login'
			}
		}

		if (window.location.pathname !== '/login' && action.type === '@profile/LOAD/SUCCESS') {
			const payload = action.payload
			if (!payload.roles.length) {
				removeAccessToken()
				removeRefreshToken()
				window.location.href = '/login'
			}
		}

		if (payload?.response?.status === 403 && window.location.pathname !== '/login') {
			on403Logout()
		}
		return next(action)
	}
