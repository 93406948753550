import { removeAccessToken, removeRefreshToken } from '@/common/token'
import { history } from '../store/reducers'

export const on403Logout = () => {
	removeAccessToken()
	removeRefreshToken()
	history.push({
		pathname: '/login',
		search: '?error=403',
	})
}
