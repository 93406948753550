import { Middleware, Dispatch, MiddlewareAPI } from 'redux'
import { State, Action } from '../store'
import { getType } from 'typesafe-actions'
import { addBuisnessProcessError } from '../actions/buisness'
import { updateCardError } from '../actions/card'
import { loadChatError, sendChatMessageError } from '../actions/chat'
import {
	changeContractError,
	createContractError,
	createPaymentAreaError,
	deletePaymentAreaError,
	loadContractError,
} from '../actions/contract'
import { loadProcessesError } from '../actions/processes'
import { history } from '../reducers'
import { removeAccessToken, removeRefreshToken } from '@/common/token'
import { on403Logout } from '@/common/logout'
let debounce

export const errorMiddleware: Middleware<State, State, Dispatch<Action>> =
	(store: MiddlewareAPI<Dispatch<Action>>): ((next: Dispatch<Action>) => (action: Action) => Action) =>
	(next: Dispatch<Action>): ((action: Action) => Action) =>
	(action: Action): Action => {
		const { type, payload }: any = action

		if (
			[
				getType(addBuisnessProcessError),
				getType(updateCardError),
				getType(sendChatMessageError),
				getType(loadChatError),
				getType(loadContractError),
				getType(createContractError),
				getType(changeContractError),
				getType(createPaymentAreaError),
				getType(deletePaymentAreaError),
				getType(loadProcessesError),
			].includes(type)
		) {
			if (payload?.response?.status === 401) {
				removeAccessToken()
				removeRefreshToken()
				history.push('/login')
			}
			if (payload?.response?.status === 403 && window.location.pathname !== '/login') {
				if (debounce) {
					clearTimeout(debounce)
				}
				debounce = setTimeout(() => {
					on403Logout()
					debounce = undefined
				}, 500)
			}
		}

		return next(action)
	}
