import React, { useState } from 'react'
import DocumentUpload from '../../../Components/Documents/DocumentUpload'
import { Col, Form, Popover, Row, Button } from 'react-bootstrap'
import axios from 'axios'
import { host } from '../../../store/store'
import { getAccessToken } from '../../../common/token'
import { useDispatch } from 'react-redux'
import { createProcessStart } from '../../../store/actions/processes'

export const AddExistingContract: React.FC<any> = ({
	id,
	student_id,
	parent_id
}) => {
	const [files, setFiles] = useState([])
	const dispatch = useDispatch()

	const submitButtonPressHandler = () => {
		dispatch(createProcessStart({
			process: 'add_existing_contract',
			id: id,
			parameters: {
				files: files.map(file => file.id)
			}
		}))
	}

	const uploadFile = async (file) => {
		return await axios.post(`${host}/api/files/`, file, {
			headers: {
				'Authorization': `Bearer ${getAccessToken()}`
			}
		}).then(res => res.data)
	}

	const uploadCourierPrice = async (documents: any) => {
		const files = []

		for (const document of documents) {
			const file = new FormData()
			file.append('file', document, document.name)
			file.append('user', String(parent_id))
			file.append('file_name', document.name.slice(0, 49))
			files.push(file) 
		}

		const contracts = await axios.all(files.map(async (file) => await uploadFile(file)))

		setFiles(prev => [...prev, ...contracts])
	}

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Загрузить договор</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						<div>
							<h6> Загруженные договоры</h6>

							{
								files.map(file => {
									return (
										<div className={'d-flex justify-content-between mb-1'}>
											<div>
												{
													file.file_name.slice(0, 20) + ' ...'
												}
											</div>

											<div className="ml-5">
												{
													file.size
												}
											</div> 
										</div>
									)
								})
							}
						</div>
						<div className="d-flex">
							<Form.Group style={{width: '100%'}} className="mr-3" controlId="formBasicEmail">
								<Form.Label></Form.Label>
								<DocumentUpload uploadDocument={uploadCourierPrice} title="Загрузить договор"/>
							</Form.Group>
						</div>

						<Button onClick={submitButtonPressHandler} variant="primary">
                            Передать
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
