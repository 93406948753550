import { BaseRestDataProvider } from './BaseRestDataProvider'
import axios from 'axios'

export class ContractDataProvider extends BaseRestDataProvider {
	loadContracts = async (payload: any) => {
		const { student, parent } = payload
		// if (!result.length) {
		//   return [await this.createContract({
		//       start: moment().format("YYYY-MM-DD"),
		//       end: moment().format("YYYY-MM-DD"),
		//       student: student,
		//       counterparty: parent,
		//       agreed: moment().format("YYYY-MM-DD"),
		//       number: '12345'
		//   }, payload.process_id)];
		// }
		let queryParams = '?'
		if (student !== undefined) {
			queryParams += `student=${student}`
		}
		if (parent !== undefined) {
			queryParams += `${student !== undefined ? '&' : ''}counterparty=${parent}`
		}

		return await axios.get(`${this.host}/payment/contracts/${queryParams}`).then((res) => res.data.results)
	}

	loadContractsPage = async (payload: any) => {
		return await axios
			.get(`${this.host}/payment/contracts/?pagination=True&page_size=20&page=${payload.page}&${payload.search}`)
			.then((res) => res.data)
	}

	createContract = (payload: any) => {
		return axios
			.post(
				`${this.host}/api/processes/${payload.process_id}/execute/`,
				{
					action_name: payload.action_name,
					parameters: payload.contract,
				},
				{
					withCredentials: true,
					xsrfCookieName: 'csrftoken',
					xsrfHeaderName: 'X-CSRFToken',
				},
			)
			.then((res) => res.data)
	}

	changeContract = (payload: any) => {
		const { student, counterparty, start, end, agreed, number, contract_id } = payload

		return axios
			.patch(
				`${this.host}/payment/contracts/${contract_id}/`,
				{
					start,
					end,
					student,
					counterparty,
					agreed,
					number,
				},
				{
					withCredentials: true,
					xsrfCookieName: 'csrftoken',
					xsrfHeaderName: 'X-CSRFToken',
				},
			)
			.then((res) => res.data)
	}

	deleteContract = (contract_id: number) => {
		return axios.delete(`${this.host}/payment/contracts/${contract_id}/`).then((res) => res.data)
	}

	createPaymentAreas = async (payload: any) => {
		return await axios.all(
			payload.paymentAreas.map((paymentArea: any) =>
				this.createPaymentArea({ ...paymentArea, contract: payload.contract }),
			),
		)
	}

	createPaymentArea = async (payload: any) => {
		return axios
			.post(`${this.host}/payment/schedules/`, payload, {
				withCredentials: true,
				xsrfCookieName: 'csrftoken',
				xsrfHeaderName: 'X-CSRFToken',
			})
			.then((res) => res.data)
	}

	deletePaymentArea = (payload: any) => {
		return axios
			.delete(`${this.host}/payment/schedules/${payload.id}/?student_id=${payload.student}`, {
				withCredentials: true,
				xsrfCookieName: 'csrftoken',
				xsrfHeaderName: 'X-CSRFToken',
			})
			.then((res) => res.data)
	}
}
