import { $BaseHttpClient } from '@utils/http/BaseHttpClient'
import axios, { AxiosError, AxiosResponse } from 'axios'
import {
	getAccessToken,
	getRefreshToken,
	removeAccessToken,
	removeRefreshToken,
	setAccessToken,
	setRefreshToken,
} from '@/common/token'
import { LastUrlService } from '@utils'
import { HttpClient as FormGeneratorHttp } from '@frontend-modules/form-generator'
import { ErrorsCaptureWrapperApi } from '@frontend-modules/error-handler'
import { API_ERROR_HANDLER_SHOW_BUTTON } from '@/src-new/constants'
import { UntraceableApiList } from './sentryApiLists'
import { ssoPath } from '@/api/path/ssoPath'
import { on403Logout } from '@/common/logout'

// TODO: переписать способ обновления токена
// на обновление только 1 раз за все запросы
const $HttpClient = new $BaseHttpClient({
	_baseURL: process.env.REACT_APP_HOST,
	_headers: {
		'Content-type': 'application/json; charset=UTF-8',
	},
})

const updateRefreshToken = (refresh) => {
	const refreshAxios = axios.create({
		baseURL: `${process.env.REACT_APP_SSO_HOST}`,
	})
	return refreshAxios.post(ssoPath.refresh, { refresh })
}

const httpClientErrorListener = (httpClient, callback) => {
	httpClient.instance.interceptors.response.use(
		async (response) => response,
		async (error) => {
			callback?.(error)
			return error
		},
	)
}

$HttpClient.instance.interceptors.request.use(async (config) => {
	const accessToken = getAccessToken()
	if (accessToken) {
		if (config) {
			config.headers['Authorization'] = `Bearer ${accessToken}`
		}
	}
	return config
})

let debounce

$HttpClient.instance.interceptors.response.use(
	(response: AxiosResponse) => response.data,
	async (error: AxiosError) => {
		const errorData = error.response
		const originalConfig = error.config
		const currentAccess = getAccessToken()
		const currentRefresh = getRefreshToken()
		if (errorData.status === 403 && window.location.pathname !== '/login') {
			if (debounce) {
				clearTimeout(debounce)
			}
			debounce = setTimeout(() => {
				on403Logout()
				debounce = undefined
			}, 500)
			throw error
		}
		if (currentAccess && currentRefresh && errorData.status === 401) {
			try {
				const newTokens: any = await updateRefreshToken(currentRefresh)
				setAccessToken(newTokens.data.access)
				if (newTokens.data.refresh) {
					setRefreshToken(newTokens.data.refresh)
				}
				return $HttpClient.instance(originalConfig)
			} catch (e) {
				removeAccessToken()
				removeRefreshToken()
				LastUrlService.save(window.location.href)
				window.location.href = window.location.origin
				throw error
			}
		}
		ErrorsCaptureWrapperApi({
			error: error,
			UntraceableApiList,
			showAlertReportBtn: API_ERROR_HANDLER_SHOW_BUTTON,
		})
		throw error
	},
)
FormGeneratorHttp.setClient($HttpClient.instance as any)

export { $HttpClient, httpClientErrorListener }
