import axios from 'axios'
import { BaseRestDataProvider } from './BaseRestDataProvider'

export class AnceteDataProvider extends BaseRestDataProvider {
	loadAncete = (student_id: any) => {
		return axios.get(`${this.host}/api/statement/${student_id}/`).then((res) => res.data)
	}

	updateAncete = (student_id: any, data: any) => {
		return axios({
			method: 'PATCH',
			url: `${this.host}/api/statement/${student_id}/update/`,
			data,
		}).then((res) => res.data)
	}

	createAncete = (data) => {
		return axios({
			method: 'POST',
			url: `${this.host}/api/statement/new/`,
			data,
		}).then((res) => res.data)
	}
}
