import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Popover, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import { createProcessStart } from '../../../store/actions/processes'
import { host } from '../../../store/store'
import { ContractStatus } from '../../../store/types/contracts'

const formatTerminated = (arr) => {
	const newArr = []

	for (const contract of arr) {
		if (contract.signed_with !== 'Terminated') {
			newArr.push(contract)
		}
	}

	return newArr
}

export const TerminateInOneClick: React.FC<any> = ({ id, student_id, parent_id }) => {
	const dispatch = useDispatch()
	const [contracts, setContracts] = useState(null)
	const [selectedContract, setSelectedContract] = useState<any>(null)

	useEffect(() => {
		axios
			.get(`${host}/payment/contracts/?student=${student_id}&counterparty=${parent_id}`)
			.then((res) => setContracts(formatTerminated(res.data.results)))
	}, [id, parent_id, student_id])

	const selectedContractChangeCallback = useCallback(
		(value) => {
			setSelectedContract(value)
		},
		[selectedContract],
	)

	const submitButtonPressHandler = () => {
		dispatch(
			createProcessStart({
				process: 'terminate_in_one_click',
				id: id,
				parameters: {
					contract_id: selectedContract?.value,
				},
			}),
		)
	}

	return (
		<Col className="p-3" md={12} xl={12}>
			<Row className="mb-3">
				<Popover.Title as="h5">Расторгнуть в 1 клик</Popover.Title>
			</Row>

			<Row>
				<Col md={12}>
					<Form>
						{Array.isArray(contracts) ? (
							<div className="d-flex mb-3">
								<Form.Group>
									<Form.Label>Выберите договор</Form.Label>
									<Select
										placeholder="Выберите договор"
										options={contracts
											.filter((contract) => contract?.status !== ContractStatus.TERMINATED)
											.map((contract) => ({
												label: contract.number,
												value: contract.id,
											}))}
										value={selectedContract}
										onChange={selectedContractChangeCallback}
									/>
								</Form.Group>
							</div>
						) : (
							<div className="mb-3">
								<Skeleton height={50} />
							</div>
						)}
						<Button
							disabled={!selectedContract?.value}
							onClick={submitButtonPressHandler}
							variant="primary"
						>
                            Подтвердить
						</Button>
					</Form>
				</Col>
			</Row>
		</Col>
	)
}
