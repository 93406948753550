import { BaseRestDataProvider } from './BaseRestDataProvider'
import axios, { AxiosResponse, AxiosError } from 'axios'
import {
	IClassesPayload,
	ICreateClassPayload,
	IEditClassPayload,
	IEditCuratorsPayload,
	IEditStudentsPayload, Tutor,
} from '../store/types/classes'

export class ClassesDataProvider extends BaseRestDataProvider {
	expelStudent = (studentGroupId: string, studentId: string): Promise<unknown> => {
		return axios
			.patch(`${this.host}/api/lms/proxy/?path=student-group/expel/external/${studentGroupId}/`, {
				student: studentId,
			})
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	loadClasses = (payload: IClassesPayload): Promise<any> => {
		return axios
			.get(`${this.host}/api/lms/proxy/?path=student-group/external/${payload.id ? `${payload.id}/` : ''}`)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	loadCurators = (): Promise<any> => {
		return axios
			.get(`${this.host}/api/lms/proxy/?path=staff-profile/external/?user_role=curator`)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	loadTutors = async (): Promise<Tutor[]> => {
		const response = await axios.get<Tutor[]>(`${this.host}/api/lms/proxy/?path=staff-profile/external/?user_role=tutor`).catch((error: AxiosError) => {
			throw error
		})
		return response.data
	}

	loadParallels = (): Promise<any> => {
		return axios
			.get(`${this.host}/api/lms/parallels/`)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	loadGlobalClasses = () => {
		return axios
			.get(`${this.host}/api/moodle/global_groups/`)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	createClass = (payload: ICreateClassPayload): Promise<any> => {
		return axios
			.post(`${this.host}/api/lms/student_group/create/`, payload)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	addCurators = (payload: IEditCuratorsPayload): Promise<any> => {
		return axios
			.patch(`${this.host}/api/lms/student_group/${payload.classId}/add_curators/`, {
				curators: payload.curators,
			})
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	removeCurators = (payload: IEditCuratorsPayload): Promise<any> => {
		return axios
			.patch(`${this.host}/api/lms/student_group/${payload.classId}/remove_curators/`, {
				curators: payload.curators,
			})
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	addStudents = (payload: IEditStudentsPayload): Promise<any> => {
		return axios
			.patch(`${this.host}/api/lms/proxy/?path=student-group/external/${payload.classId}/add-students/`, {
				students: payload.students,
			})
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	removeStudents = (payload: IEditStudentsPayload): Promise<any> => {
		return axios
			.patch(`${this.host}/api/lms/student_group/${payload.classId}/remove_students/`, {
				students: payload.students,
			})
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	editClass = (payload: IEditClassPayload): Promise<any> => {
		const { id, ...data } = payload

		return axios
			.patch(`${this.host}/api/educational_classes/${payload.id}/`, data)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}

	deleteClass = (payload: IClassesPayload): Promise<any> => {
		return axios
			.delete(`${this.host}/api/educational_classes/${payload.id}/`)
			.then((res: AxiosResponse) => {
				return res?.data
			})
			.catch((error: AxiosError) => {
				throw error
			})
	}
}
